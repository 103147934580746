import * as React from "react";
import { Link } from "gatsby";
import TitleIntro from "components/sections/titleIntro";
import BaseButton from "components/button/BaseButton";
import { StaticImage } from "gatsby-plugin-image"
import {Seo} from "components/seo";

const NotFoundPage = () => {
  return (
    <>
      <Seo title="VingtCinq | Fabrique Digitale - Merci" description={"Merci pour votre demande de contact. Nous reviendrons vers vous prochainement."} />
      <TitleIntro className="lg:pb-16" title="Merci !" />
      <section className="container mb-16 lg:mb-28 lg:px-52 flex flex-col items-center">
        <figure className="w-80 transition-image relative" data-aos="custom">
          <StaticImage
            src="../../../static/home/thanks.png"
            className="mb-5"
            loading="eager"
            placeholder="none"
            alt="merci page"
          />
          <span className="transition-layer !duration-[.4s]" />
        </figure>
        <p className="text-b1 mb-5 translate-left delay-025" data-aos="custom">
          Votre message a bien été envoyé.
        </p>
        <BaseButton tag={Link} to="/" size="md" variant="light" className="delay-035 translate-left" data-aos="custom">
          Retour
        </BaseButton>
      </section>
    </>
  );
};

export default NotFoundPage;
